import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { ApiRequest, WHATA_ENDPOINTS } from "@plinknz/tah-website-elements";
import * as ROUTES from "../../../../config/router";
import { EMAIL_REGEX } from "../../../../config/regex";
import { Field } from "../../../../components/form/field";
import { UserContext } from "../../../../service/reducers/user";
import { whataRequest } from "../../../../service/member/whata";
import { parseError } from "../../../../utility/parse-error-html";

interface EmailLoginFormProps {
    loginWithToken: (token: string) => void;
    whata?: ApiRequest;
}

interface FormData {
    email: string;
    password: string;
}

export const EmailLoginForm = ({
    loginWithToken,
    whata = whataRequest,
}: EmailLoginFormProps) => {
    const {
        state: { error },
        dispatch,
    } = React.useContext(UserContext);
    const { register, handleSubmit, errors, trigger, getValues } = useForm<
        FormData
    >();

    const [passwordError, setPasswordError] = useState(false);
    const [noUserError, setNoUserError] = useState(false);

    function resetError() {
        dispatch({ type: "error", error: "" });
    }

    useEffect(() => {
        if (error) {
            setPasswordError(error === "Incorrect password");
            setNoUserError(!!error.match("No user found"));
        } else {
            setPasswordError(false);
            setNoUserError(false);
        }
        return resetError;
    }, [error]);

    async function handleFormSubmit(data: FormData) {
        dispatch({ type: "loading", isLoading: true });

        try {
            const whataResponse = await whata.post<{ token: string } | string>(
                WHATA_ENDPOINTS.login(),
                {
                    username: data.email,
                    password: data.password,
                }
            );

            if (
                typeof whataResponse.data !== "string" &&
                whataResponse?.data?.token
            ) {
                loginWithToken(whataResponse?.data.token);
            } else if (typeof whataResponse.data === "string") {
                const responseError =
                    parseError(whataResponse.data) || undefined;

                throw new Error(responseError);
            }
        } catch (submitError: unknown) {
            console.warn(submitError);
            let errorMessage = "Something went wrong trying to log you in";

            if (submitError instanceof Error) {
                errorMessage = submitError.message;
            }

            dispatch({ type: "error", error: errorMessage });
        } finally {
            dispatch({ type: "loading", isLoading: false });
        }
    }

    const isValid = () =>
        !errors.email &&
        !errors.password &&
        getValues().email &&
        getValues().password;

    return (
        <form
            className="form"
            data-testid="email-form"
            onSubmit={handleSubmit(handleFormSubmit)}>
            {error &&
                ((noUserError && (
                    <div data-testid="email-form-error" className="form-hint">
                        We do not have your email in our database. Please{" "}
                        <a
                            href="mailto:tari@ngatikuia.iwi.nz?subject=Inquiries from Ngāti Kuia website"
                            target="_blank"
                            rel="noreferrer">
                            click here
                        </a>{" "}
                        to send us a message.
                    </div>
                )) ||
                    (passwordError && (
                        <div
                            data-testid="email-form-error"
                            className="form-hint">
                            {error}, please{" "}
                            <Link to={ROUTES.membersLoginReset()}>
                                click here
                            </Link>{" "}
                            if you need to reset your password.
                        </div>
                    )) || (
                        <div
                            data-testid="email-form-error"
                            className="field-error">
                            {error}
                        </div>
                    ))}
            <Field
                label="Email Address"
                name="email"
                error={{
                    hasError: !!errors.email,
                    message: "Please enter a valid email address",
                }}
                ref={register({ required: true, pattern: EMAIL_REGEX })}
                placeholder="Email address"
                onChange={async (e) => trigger(e.currentTarget.name as "email")}
                type="text"
                data-testid="email"
                required
            />
            <Field
                label="Password"
                name="password"
                error={{
                    hasError: !!errors.password,
                    message: "Please enter your password",
                }}
                ref={register({ required: true })}
                placeholder="Password"
                onChange={async (e) =>
                    trigger(e.currentTarget.name as "password")
                }
                type="password"
                data-testid="password"
                required
            />
            <div className="form-navigation">
                <button
                    className="button primary"
                    type="submit"
                    data-testid="login-button"
                    disabled={!isValid()}>
                    Login
                </button>
                <Link
                    to={ROUTES.membersLogin()}
                    className="button tertiary next">
                    Back
                </Link>
                <Link
                    to={ROUTES.membersLoginReset()}
                    className="button tertiary">
                    Reset password
                </Link>
            </div>
        </form>
    );
};
